import React, { useEffect, useState } from "react"
import Lightbox from "react-image-lightbox"
import ExpandBtn from "../images/expand_btn.svg"

const tryRequire = (path, i) => {
  try {
    return require(`../images/gallery-${path}-${i}.jpg`)
  } catch (err) {
    return null
  }
}

const ExpandableSection = props => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    if (lightboxIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  })

  const _closeLightbox = () => {
    setLightboxIsOpen(false)
    setCurrentImage(0)
  }

  const _openLightbox = index => {
    setLightboxIsOpen(true)
    setCurrentImage(index)
  }

  const _moveNextImg = () => {
    setCurrentImage((currentImage + 1) % _lightboxImages.length)
  }

  const _movePrevImg = () => {
    setCurrentImage(
      (currentImage + _lightboxImages.length - 1) % _lightboxImages.length
    )
  }

  const _lightboxImages = [
    tryRequire(props.id, 1) && tryRequire(props.id, 1),
    tryRequire(props.id, 2) && tryRequire(props.id, 2),
    tryRequire(props.id, 3) && tryRequire(props.id, 3),
  ]

  return (
    <div id={props.id} className={`section--expandable  animated fadeIn`}>
      <div
        className={`section--content align--${
          props.imageAlign
        } section--content--${
          props.collapsed ? "closed" : "expanded"
        } container `}
      >
        {props.active ? (
          <>
            <div
              className="section--expandable--active__image animated fadeInBigDown"
              style={{
                background: `url(${require(`../images/determined-expanded-${
                  props.image
                }.png`)}) center bottom no-repeat`,
                backgroundSize: "contain",
              }}
            />
            <div
              className={`section--expandable__content animated fadeInBigDown `}
            >
              <div className={`section--expandable__content__expand`}>
                <div>
                  <button
                    className="btn btn--close animated "
                    onClick={() => props.onClose(props.id)}
                  />
                  <h1 className="title--small">{props.title}</h1>
                  <hr className={props.id} />
                  <iframe
                    src={props.mainVideo}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="animated fadeIn main-video"
                  />
                  <div dangerouslySetInnerHTML={{ __html: props.content }} />
                  <h2 className="subtitle">{props.subtitle}</h2>
                  <div dangerouslySetInnerHTML={{ __html: props.content2 }} />
                  <h2 className="subtitle">{props.subtitle2}</h2>
                  <div dangerouslySetInnerHTML={{ __html: props.content3 }} />
                  <div className="gallery">
                    {// generating the list of images based on the array
                    _lightboxImages.map((img, index) => {
                      return (
                        <img
                          key={index}
                          src={img}
                          onClick={() => _openLightbox(index)}
                          className="animated"
                        />
                      )
                    })}
                    {// If lightbox is open
                    lightboxIsOpen && (
                      <Lightbox
                        mainSrc={_lightboxImages[currentImage]}
                        nextSrc={
                          _lightboxImages[
                            (currentImage + 1) % _lightboxImages.length
                          ]
                        }
                        prevSrc={
                          _lightboxImages[
                            (currentImage + _lightboxImages.length - 1) %
                              _lightboxImages.length
                          ]
                        }
                        onMovePrevRequest={_movePrevImg}
                        onMoveNextRequest={_moveNextImg}
                        onCloseRequest={_closeLightbox}
                        enableZoom={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="section--expandable--close__image animated fadeIn"
              style={{
                background: `url(${require(`../images/determined-collapsed-${
                  props.image
                }.png`)}) center bottom no-repeat`,
              }}
            />
            <div className={`section--expandable__content `}>
              <div className={`section--expandable__content__collapsed`}>
                <h1 className="title--small">{props.title}</h1>
                <hr className={props.id} />
                <h2 className="subtitle">{props.subtitle}</h2>
                <p>{props.excerpt}</p>
                <div className="back">
                  <div
                    className="button_base btn_3d_roll"
                    onClick={() => props.onExpand(props.id)}
                  >
                    <div>Watch +</div>
                    <div>Watch +</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        hidden={!props.active}
        className={`section--bottom section--bottom__${props.id}`}
      />
    </div>
  )
}

export default ExpandableSection
