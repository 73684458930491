import React, { useEffect } from "react"
import Swiper from "swiper"

const HeroSlider = props => {
  const data = props.data

  useEffect(() => {
    var swiper = new Swiper(".swiper-container", {
      // Optional parameters
      slidesPerView: 1,
      initialSlide: 1,
      resistanceRatio: 0,
      direction: "horizontal",
      loop: true,
      autoplay: true,
      speed: 3000,
    })
  }, [])

  return (
    <div className="hero">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {data.map((item, index) => {
            return (
              <div
                className="swiper-slide"
                key={index}
                style={{
                  background: `url(${require(`../images/determined-site-header-${
                    item.image
                  }.jpg`)}) right bottom no-repeat`,
                  backgroundSize: "contain",
                }}
              >
                <div className="container">
                  <h1 className="title">{item.title}</h1>
                  <h2 className="subtitle">{item.subtitle}</h2>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HeroSlider
