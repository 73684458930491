import * as R from "ramda"
import React, { useEffect, useState } from "react"
import Swiper from "swiper"

import Layout from "../components/layout"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import ExpandableSection from "../components/ExpandableSection.jsx"
import HeroSlider from "../components/HeroSlider"

import Data from "../content.json"
import HeroData from "../hero_slider.json"

const IndexPage = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [activeIndex, setActiveIndex] = useState("")
  const [active, setActive] = useState(false)

  useEffect(() => {
    activeIndex &&
      window.scrollTo({
        behavior: "smooth",
        top: document.getElementById(activeIndex).offsetTop,
      })
  })

  const _handleCollapse = id => {
    setCollapsed(!collapsed)
    _handleActiveItem(id)
  }

  const _handleActiveItem = item => {
    const itemId = document.getElementById(item)
    setActive(prevState => (R.equals(prevState, item) ? !active : true))
    setActiveIndex(item)
  }

  const _handleCloseBtn = item => {
    setActive(false)
    setCollapsed(!collapsed)
    setActiveIndex("")
  }

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <HeroSlider data={HeroData} />
      <div className="page">
        <div className="intro">
          <div className="intro--content container">
            <h1 className="intro--content__title subtitle">
              What is Determined?
            </h1>
            <p className="intro--content__paragraph">
              It’s about two important things: first, empowering individuals and
              communities to define themselves and to change the narrative
              surrounding tobacco use. And second, educating the general public
              about health, social and political issues facing our diverse Utah
              communities.
            </p>
          </div>
        </div>
        {Data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ExpandableSection
                active={R.equals(item.id, activeIndex) && active ? true : false}
                collapsed={collapsed}
                content={item.content}
                content2={item.content2}
                content3={item.content3 || ""}
                excerpt={item.excerpt}
                id={item.id}
                image={index + 1}
                imageAlign={item.imageAlign}
                mainVideo={item.mainVideo}
                onClose={_handleCloseBtn}
                onExpand={_handleCollapse}
                title={item.title}
                subtitle={item.subtitle}
                subtitle2={item.subtitle2 || ""}
              />
            </React.Fragment>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage
